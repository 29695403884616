<template lang="pug">
.landing-header
  .landing-header__block
    LogoNpo.logo
    .landing-header__drops
      header-dropdown(
        v-for="item of menu"
        :key="item.links"
        :name="item.name"
        :links="item.links"
        :is-dropdown="item.isDropdown"
        :href="item.href"
      )
    .landing-header__site-navs
      header-dropdown(name="Возможности системы" :is-dropdown="false" @click="scrollIntoView('system-preferences', 'start')")
      header-dropdown(name="Тарифы" :is-dropdown="false" @click="showTariff")
      header-dropdown(name="Контакты" :is-dropdown="false" @click="showContacts")
  .landing-header__block.landing-header__block-right
    a.menu-link(:href="FreePhoneNumber.link")
      ui-icon(:icon="UiIconNames.Icon_Phone" :size="14")
      | {{ FreePhoneNumber.value }}
      |
      | бесплатно по РФ
    .landing-header__actions
      ui-button.orange-button(type="secondary" @click="showDialog('registration')") Регистрация
      ui-button.orange-button(@click="showDialog('authByLogin')") Вход
    .landing-header__menu(@click="drawer = true")
      ui-icon(:icon="UiIconNames.Menu_Main" :size="16")
      span МЕНЮ

.side-drawer
  ClientOnly
    el-drawer(v-model="drawer" direction="rtl")
      template(#header)
        h4.side-menu__header Меню
      template(#default)
        el-collapse(v-model="activeName" accordion)
          el-collapse-item(v-for="m of menu" :title="m.name" :name="m.name" :key="m.name")
            .side-menu__collapse-content
              a(v-for="link of m.links" :key="link.title" :href="link.href" target="_blank") {{ link.title }}
        .side-menu__site-navs
          span(@click="scrollAndClose('system-preferences')") Возможности системы
          span(@click="scrollAndClose('tariffs')") Тарифы
          span(@click="scrollAndClose('contacts')") Контакты
        .side-menu__actions
          ui-button(type="secondary" @click="showDialog('registration')") Регистрация
          ui-button(@click="showDialog('authByLogin')") Вход
        .side-menu__footer
          .divider
          .side-menu__logo
            LogoNpo.logo
            a.side-menu__menu-link(:href="FreePhoneNumber.link")
              ui-icon(:icon="UiIconNames.Icon_Phone" :size="14")
              | {{ FreePhoneNumber.value }}
              |
              | бесплатно по РФ
          span.side-menu__copyright © 2009 - {{ year }} НПО Система.

authorization-form(
  v-model:show="showAuthDialog"
  v-model:mode="modeAuth"
)
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { useLandingLogin } from "@/use/auth/useLandingLogin";
import { useRouter } from "vue-router";
import { getYear } from "@/utils/formatter/dateFormatter";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { FreePhoneNumber } from "@/const/contacts";

import type { LandingMenuItemI } from "@/use/auth/useLandingLogin";

import LogoNpo from "@/assets/logo/LOGO-NPO.svg?component";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import AuthorizationForm from "@/components/authorization/AuthorizationFormDialog.vue";
import HeaderDropdown from "@/components/ui/dropdown/HeaderDropdown.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "LandingHeader",
  components: {
    UiIcon,
    UiButton,
    AuthorizationForm,
    HeaderDropdown,
    LogoNpo,
  },
  setup() {

    const router = useRouter();
    const { modeAuth, showAuthDialog, drawer, activeName, showDialog } = useLandingLogin();

    const { storage } = useServiceStorage();

    const year = getYear();

    const menu : LandingMenuItemI[] = [{
      name: 'Компания',
      links: [
        { title: 'О компании', href: 'https://nposys.ru/company/' },
        { title: 'Контакты', href: 'https://nposys.ru/contact/' },
        { title: 'Вопрос - ответ', href: 'https://nposys.ru/answer/' },
        { title: 'Отзывы', href: 'https://nposys.ru/references/' },
      ]
    }, {
      name: 'Услуги',
      links: [
        { title: 'Тендерное сопровождение', href: 'https://nposys.ru/owner/' },
        { title: 'Экспертный поиск тендеров', href: 'https://nposys.ru/expert-search/' },
        { title: 'Юридические консультации', href: 'https://nposys.ru/legal-advice/' },
        { title: 'Представительство в УФАС. Обжалование', href: 'https://nposys.ru/complaint/' },
        { title: 'Банковские гарантии', href: 'https://nposys.ru/bank-guarantee/' },
        { title: 'Подготовка заявок', href: 'https://nposys.ru/application/' },
      ]
    }]

    function scrollAndClose(id: string) {
      drawer.value = false;
      if (id === 'tariffs') showTariff();
      else scrollIntoView(id, 'start')
    }

    function showTariff() {
      if (router.currentRoute.value.name === Tabs.Landing) {
        scrollIntoView('tariffs', 'start')
      } else {
        storage.scrollIntoTariff = true;
        router.push({ name: Tabs.Landing})
      }
    }

    function showContacts() {
      if (router.currentRoute.value.name === Tabs.Landing) {
        scrollIntoView('contacts', 'start')
      } else {
        storage.scrollIntoContacts = true;
        router.push({ name: Tabs.Landing})
      }
    }

    return {
      year,
      menu,
      showAuthDialog,
      modeAuth,
      drawer,
      activeName,
      showContacts,
      showDialog,
      scrollIntoView,
      scrollAndClose,
      showTariff,
      UiIconNames,
      FreePhoneNumber,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/other/sideMenu';

.landing-header {
  position: sticky;
  top: 0;
  z-index: 3;
  height: var(--landing-header-height);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 8px 32px;
  background-color: #f9f9f9;
  box-shadow: 0 20px 18px -13px rgba(205.5,205.5,205.5,.5);
}

.logo {
  height: 32px;
  width: auto;
}

.landing-header__block {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.menu-link {
  @include menu-link;
}

.landing-header__actions {
  display: flex;
  flex-flow: row;
  gap: 8px;

  .orange-button {
    width: 106px;
    justify-content: center;
  }

  ::v-deep(.ui-button-primary) {
    color: var(--main-text-color);
    background-color: #FFC310;

    &:hover {
      background-color: #F59D14;
    }
  }

  ::v-deep(.ui-button-secondary) {
    color: #ed7a1d;
    border: 1px solid #FFC310;

    &:hover {
      color: #ed7a1d;
      border: 1px solid #FFC310;
      background-color: #fff6ec;
    }
  }
}

.landing-header__menu {
  display: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  gap: 8px;
  align-items: center;

  cursor: pointer;
}

@media (max-width: 1160px) {
  .menu-link {
    display: none;
  }
}

@media (max-width: 1040px) {
  .landing-header__menu {
    display: flex;
  }
  .landing-header__drops {
    display: none;
  }
  .landing-header__block-right {
    gap: 24px;
  }
}

@media (max-width: 860px) {
  .landing-header__site-navs {
    display: none;
  }
}

@media (max-width: 520px) {
  .landing-header__actions {
    display: none;
  }
}
</style>
